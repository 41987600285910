import { ref, watch, computed } from '@vue/composition-api'

const loading = ref(false);
// const numPaxRequested = ref(1);
// const allAvailabilities = ref<AvailabilityResponse[]>([]);
// const services = ref<AvailabilityResponse[]>([]);
// const existingReservationId = ref<number>();
// const reservation = ref<ReservationCart>(new ReservationCart());


export function useCategories() {
    // const { fetchData, forcedPickPoint } = useWidget();
    // const { t } = useI18n();

    const helloWorld = async () => {
        console.log("Hello World");
    }

    return { 
        loading, helloWorld
    }
}