import { ref, watch, computed, Ref } from '@vue/composition-api';
import axios from "axios";
import { faqModel } from "@/models/faqModel";

export function useFaq() {

  // dev
  const faq: Ref<faqModel[]> = ref([
    {
      id: 1,
      question: "Nel momento in cui si effettua un ordine, dopo quanto tempo la richiesta viene presa in carico e si procede alla spedizione del materiale?",
      answer: "Il 96% degli ordini ricevuti in azienda viene evaso nei tempi comunicati nella Conferma d’Ordine che inviamo in e-mail non appena l’ordine viene inserito nel flusso di lavorazione. La prima fonte di informazione utile è, quindi, la nostra Conferma d’Ordine; controllare sempre nella casella di posta o nello Spam o, se preferisci, mandaci una mail a info@sisas.it con l’oggetto: “Indirizzo per l’invio delle Conferme d’Ordine”, indicando a quale indirizzo preferisci riceverle e consentendoci di allineare le informazioni in nostro possesso con le Tue reali necessità. Grazie alla nuova Organizzazione dell’Ufficio Vendite, riusciamo a gestire (inviare la Conferma d’Ordine o richiedere ulteriori chiarimenti in merito) normalmente entro un giorno lavorativo dalla ricezione. L’ulteriore valutazione che devi fare per calcolare il tempo di consegna è legata al servizio di trasporto. Il nostro Partner per i trasporti serve tutta l’Italia con differenti fasce di consegna che possono andare dalle 24 alle 96 ore dalla presa in carico. Verifica con l’Ufficio Vendite quali sono i tempi medi per la Tua zona e, se hai esigenze specifiche ed inderogabili, ricordati di segnalarlo al momento dell’ordine per consentirci, previa quotazione apposita, di utilizzare un servizio di consegna Espressa. A titolo esemplificativo medio (dall’ordine di un rimorchio segnaletico, fino ad un ordine di specchi stradali o coni, passando per ordini di segnaletica aziendale o stradale) considera che passano circa 5 giorni lavorativi dal momento in cui riceviamo un ordine al momento in cui lo carichiamo sul mezzo per la messa in consegna. Come ultimo step di controllo per precisare la consegna, è possibile calcolare dal momento in cui si riceve il D.D.T. via mail i giorni di servizio per la zona di destinazione ed ottenere, salvo eventuali disguidi o impedimenti del trasportatore, l’esatta data di consegna."
    },
    {
      id: 2,
      question: "Come richiedere il documento di trasporto o la fattura dell’ordine?",
      answer: "Il documento di trasporto viene inviato telematicamente nel momento in cui il tuo ordine viene caricato sul mezzo per la messa in consegna. Controlla nella Tua casella di posta o nello Spam o se preferisci, mandaci una mail a info@sisas.it con l’oggetto: Indirizzo per l’invio dei D.D.T., indicando su quale indirizzo preferisci riceverle e consentendoci di allineare le informazioni in nostro possesso con le Tue reali necessità. La Fattura viene inviata telematicamente nel momento in cui viene emessa. Controlla nella Tua casella di posta o nello Spam o se preferisci, mandaci una mail a info@sisas.it con l’oggetto: Indirizzo per l’invio delle Fatture, indicando su quale indirizzo preferisci riceverle e consentendoci di allineare le informazioni in nostro possesso con le Tue reali necessità. Per qualsiasi eventuale necessità differente da quelle che possiamo prevedere, tutti i documenti possono essere richiesti direttamente al Centralino Generale allo 075.51.864.1 Interno 9, oppure inviando una mail all’ufficio che segue i tuoi acquisti agli indirizzi vendite.nord@sisas.it o vendite.sud@sisas.it."
    },
    {
      id: 3,
      question: "Dove reperire i dettagli dei prodotti e le schede tecniche?",
      answer: "Le Schede Tecniche dei prodotti, sono disponibili per il download gratuito direttamente sul nostro sito web. Qualora si presentino problemi e difficoltà nel download dei file, o non si sia trovata la Scheda desiderata, è possibile richiedere tramite e-mail a info@sisas.it le schede tecniche e le informazioni necessarie, che verranno dunque fornite direttamente per posta elettronica."
    },
    {
      id: 4,
      question: "Come richiedere informazioni e specifiche tecniche più dettagliate sui singoli prodotti?",
      answer: "Le specifiche dei prodotti sono presenti nelle schede tecniche scaricabili gratuitamente dal sito web. Informazioni più dettagliate possono essere richieste direttamente per via telefonica contattando il centralino aziendale e richiedendo l’assistenza del reparto specifico o più semplicemente inviando una mail di richiesta a info@sisas.it completa dei dati del richiedente e delle specifiche dell’articolo in questione."
    },
    {
      id: 5,
      question: "Come richiedere informazioni o assistenza tecnica relativi agli articoli di elettronica?",
      answer: "In merito a richieste particolari sugli articoli di elettronica, sulle loro specifiche o sulla risoluzione di problematiche relative, è possibile contattare telefonicamente il centralino, il quale si occuperà di inoltrare la telefonata al reparto di competenza, il Customer Care della Divisione Elettronica allo 075.51.864.1 Interno 4."
    },
    {
      id: 6,
      question: "Come richiedere i prezzi degli articoli e le eventuali scontistiche applicate/disponibili?",
      answer: "Le informazioni specifiche relative ai prezzi e alle scontistiche applicate ai prodotti sono riservate solo ai clienti già presenti nel database aziendale, anche nel caso in cui di tratti di clienti facenti parte del portfolio di un agente; in questo caso è possibile contattare via mail il Servizio Clienti con cui si intrattengono i rapporti agli indirizzi vendite.nord@sisas.it o vendite.sud@sisas.it per richiedere le informazioni commerciali desiderate o, nel dubbio al Centralino Generale allo 075.51.864.1 Interno 9 che dopo aver raccolto le informazioni necessarie, inoltrerà la richiesta ai colleghi di pertinenza."
    },
    {
      id: 7,
      question: "È possibile effettuare gli ordini telefonicamente?",
      answer: "Certo, si possono effettuare ordini telefonici, in particolare se si tratta di un primo ordine così da poter verificare verbalmente tutti i dati necessari alla gestione iniziale. Invitiamo tuttavia tutti i Clienti, ad effettuare gli ordini seguenti via e-mail o fax per ridurre al minimo il margine di errore per le trascrizioni e/o interpretazioni delle richieste trattate soltanto telefonicamente."
    },
    {
      id: 8,
      question: "È possibile richiedere informazioni sullo stato della realizzazione dei bozzetti in corso d’opera?",
      answer: "Certamente, è possibile richiedere informazioni sui bozzetti anche in corso d’opera. Le richieste relative allo stato dell’arte nella realizzazione dei bozzetti vengono inoltrate all’Ufficio competente e possono essere anticipate al Centralino Generale allo 075.51.864.1 Interno 9, oppure inviate a mezzo mail a studiografico@sisas.it."
    },
    {
      id: 9,
      question: "Come devo gestire eventuali problemi di consegna merce (merce danneggiata, mancante...)?",
      answer: "È fondamentale che venga inviata una e-mail con gli estremi della spedizione o dell’ordine di riferimento e/o una o più fotografie se si tratta di materiale danneggiato, che possa essere inoltrata all’Ufficio Reclami che prenderà in carico tutte le attività di indagine in merito al disservizio segnalato, e troverà la soluzione più idonea per ogni caso si dovesse verificare."
    },
    {
      id: 10,
      question: "Come inoltrare una domanda di assistenza o supporto nel caso di problemi relativi alla consegna della merce o alle sue condizioni (ad es. merce danneggiata)?",
      answer: "Per ogni necessità di supporto in merito a problematiche relative alla consegna o alle condizioni della merce (mancata consegna, merce danneggiata…), è possibile richiedere assistenza. Nel caso di mancata consegna della merce, inviando un’e-mail con i dati della spedizione. Nel caso di merce danneggiata, inviando un’e-mail con foto del materiale danneggiato. Tutte le richieste verranno inoltrate all’Ufficio Reclami che prenderà in carico tutte le attività di indagine in merito al disservizio segnalato, e troverà la soluzione più idonea per ogni caso si dovesse verificare."
    },
    {
      id: 11,
      question: "Come richiedere informazioni di carattere amministrativo?",
      answer: "Tutte le richieste di carattere amministrativo possono essere inoltrate direttamente al reparto di competenza allo 075.51.86.464 o preferibilmente via mail a amministrazione@sisas.it."
    }
  ]);

  const faqDetail: Ref<faqModel> = ref(null);

  const getFaq = async () => {
    try {
      // const res = await axios.get<faqModel[]>("/faq");
      // faq.value = res.data ? res.data : new Array<faqModel>();
      // return faq;

      return faq; // dev
    } catch (error) {
      console.error(error);
    }
  }

  const getFaqById = async (id: number | string) => {
    try {
      // const res = await axios.get<faqModel>("/faq?id=" + id);
      // faqDetail.value =  res.data;

      faqDetail.value = faq.value.find(f => f.id == id); // dev
    } catch (error) {
      console.error(error);
    }
  }

  return { 
    faq,
    faqDetail,
    getFaq,
    getFaqById
  }
}